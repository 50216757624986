// vendors
@import "../../node_modules/bootstrap/scss/bootstrap";
@import 'vendors/bs5/bootstrap';

// sass-utils
@import 'sass-utils/variables';
@import 'sass-utils/mixins';
@import 'sass-utils/functions';
@import 'sass-utils/helpers';

// base
@import 'base/reset';
@import 'base/typography';

// components
@import 'components/buttons';
@import 'components/site-section';

// layout
@import 'layout/site-header';
@import 'layout/site-footer';