.site-header {
    background: var(--tertiary);
    will-change: transform;
    transition: transform 200ms linear;
    
    &--top {
        position: relative;
    }

    &--fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
    }

}