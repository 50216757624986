.bg--primary {
  background-color: var(--primary);
}

.bg--secondary {
  background-color: var(--secondary);
}

.bg--tertiary {
  background-color: var(--tertiary);
}

.bg--danger {
  background-color: var(--danger);
}

.bg--info {
  background-color: var(--info);
}

.bg--success {
  background-color: var(--success);
}

.bg--none {
}