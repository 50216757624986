.site-section {
    &:not(:last-of-type) {
        margin-bottom: 80px;
    }

    @import "pagebuilder/accordion";
    @import "pagebuilder/form";
    @import "pagebuilder/headline";
    @import "pagebuilder/hero";
    @import "pagebuilder/iframeHtml";
    @import "pagebuilder/imageAndText";
    @import "pagebuilder/media";
    @import "pagebuilder/slider";
    @import "pagebuilder/teaserCards";
    @import "pagebuilder/text";
}
